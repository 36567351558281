import React, { useState, useEffect } from "react"
import SVGAnim from '../images/svg/Archebyte_logotype_snapshot.inline.svg'
import { useSelector, useDispatch} from 'react-redux'
import { animState, showNav } from "../state/actions/animationActions";

function LogoType(props){

    const animation = useSelector(store => store.anim)
    // console.log("animation.played in LOGOTYPE: ", animation.played)
    // console.log("showNav in LOGOTYPE: ", show)

    const dispatch = useDispatch()
    const [hideHero, setHideHero] = useState(false)

    const endNow = () => {
        dispatch(showNav(true))
        dispatch(animState(true))
    }

    const endOfAnim = () => {
          //close the hero by removeing the class
        // console.log("Hiding Hero")
        setHideHero(() => true)
        // console.log("removing content push");

        setTimeout(()=>{
            // console.log("Dispatching animState(true)")
            dispatch(showNav(true))
            dispatch(animState(true)) //dispatch to set animationPlayed to true  
        }, 2000)  // Give time for the height anim to complete. running asynchrnously (guesstimation)
    }

    
    useEffect(()=>{
        
        if(animation.played === false) {
            const lastAnim = document.getElementsByClassName("OUTLINE-TYPE")[0]
            // after the last animation on the logo plays (the outline type)
            lastAnim.onanimationend = () => {
                // console.log(">>> animation end <<<")
                endOfAnim()
            }
        } else {
            // console.log("endingNow...")
            endNow()
        }
       
       
   }, [animation.played])


        return (
            <div className={`logoAnimation  ${hideHero ? "close-anim" : "" }`} >
                 <SVGAnim />
            </div>
        )
   
}

export default LogoType

